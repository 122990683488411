import { isBrowser } from "@helpers"
export const ui = {
  state: {
    navOpen: false,
    siteBannerOpen: true,
  },
  reducers: {
    toggleNav(state) {
      if (isBrowser) {
        const body = document.querySelector("body")

        if (state.navOpen) {
          body.className = ""
        } else {
          body.className += "modal-opened"
        }
      }

      return {
        ...state,
        navOpen: !state.navOpen,
      }
    },
    openNav(state) {
      if (isBrowser) {
        const body = document.querySelector("body")
        body.className += "modal-opened"
      }
      return {
        ...state,
        navOpen: true,
      }
    },
    closeNav(state) {
      if (isBrowser) {
        const body = document.querySelector("body")
        body.className = ""
      }
      return {
        ...state,
        navOpen: false,
      }
    },
    closeSiteBanner(state) {
      return {
        ...state,
        siteBannerOpen: false,
      }
    },
  },
}
