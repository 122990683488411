import "babel-polyfill"
import "normalize.css"

import browserWrapper from "@src/rootBrowser"
import pageWrapper from "@src"
import { useEffect, useRef } from "react"

export const wrapRootElement = browserWrapper
export const wrapPageElement = pageWrapper

const exploreRoutes = [
    '/identity-and-stats',
    '/people-and-stories',
    '/allyship',
]

export const shouldUpdateScroll = ({
    routerProps: { location },
    prevRouterProps,
}) => {
    const prevLocation = prevRouterProps?.location
    if (exploreRoutes.includes(location.pathname) && exploreRoutes.includes(prevLocation?.pathname)) {
        return false
    }
    if (location.pathname !== prevLocation?.pathname) {
        return [0, 0]
    }
    return false
}