exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-may-17-js": () => import("./../../../src/pages/about-may-17.js" /* webpackChunkName: "component---src-pages-about-may-17-js" */),
  "component---src-pages-allyship-js": () => import("./../../../src/pages/allyship.js" /* webpackChunkName: "component---src-pages-allyship-js" */),
  "component---src-pages-contact-fundraising-js": () => import("./../../../src/pages/contact/fundraising.js" /* webpackChunkName: "component---src-pages-contact-fundraising-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-contact-partnerships-js": () => import("./../../../src/pages/contact/partnerships.js" /* webpackChunkName: "component---src-pages-contact-partnerships-js" */),
  "component---src-pages-contact-training-and-speakers-js": () => import("./../../../src/pages/contact/training-and-speakers.js" /* webpackChunkName: "component---src-pages-contact-training-and-speakers-js" */),
  "component---src-pages-fundraise-js": () => import("./../../../src/pages/fundraise.js" /* webpackChunkName: "component---src-pages-fundraise-js" */),
  "component---src-pages-get-started-js": () => import("./../../../src/pages/get-started.js" /* webpackChunkName: "component---src-pages-get-started-js" */),
  "component---src-pages-host-an-activity-js": () => import("./../../../src/pages/host-an-activity.js" /* webpackChunkName: "component---src-pages-host-an-activity-js" */),
  "component---src-pages-identity-and-stats-js": () => import("./../../../src/pages/identity-and-stats.js" /* webpackChunkName: "component---src-pages-identity-and-stats-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-our-impact-js": () => import("./../../../src/pages/our-impact.js" /* webpackChunkName: "component---src-pages-our-impact-js" */),
  "component---src-pages-partner-js": () => import("./../../../src/pages/partner.js" /* webpackChunkName: "component---src-pages-partner-js" */),
  "component---src-pages-people-and-stories-js": () => import("./../../../src/pages/people-and-stories.js" /* webpackChunkName: "component---src-pages-people-and-stories-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-register-js": () => import("./../../../src/pages/register.js" /* webpackChunkName: "component---src-pages-register-js" */),
  "component---src-pages-resources-js": () => import("./../../../src/pages/resources.js" /* webpackChunkName: "component---src-pages-resources-js" */),
  "component---src-pages-shop-index-js": () => import("./../../../src/pages/shop/index.js" /* webpackChunkName: "component---src-pages-shop-index-js" */),
  "component---src-pages-shop-school-js": () => import("./../../../src/pages/shop/school.js" /* webpackChunkName: "component---src-pages-shop-school-js" */),
  "component---src-pages-shop-workplace-js": () => import("./../../../src/pages/shop/workplace.js" /* webpackChunkName: "component---src-pages-shop-workplace-js" */),
  "component---src-pages-training-and-speakers-js": () => import("./../../../src/pages/training-and-speakers.js" /* webpackChunkName: "component---src-pages-training-and-speakers-js" */),
  "component---src-shop-templates-product-template-js": () => import("./../../../src/shop/templates/productTemplate.js" /* webpackChunkName: "component---src-shop-templates-product-template-js" */),
  "component---src-templates-about-page-template-js": () => import("./../../../src/templates/aboutPageTemplate.js" /* webpackChunkName: "component---src-templates-about-page-template-js" */),
  "component---src-templates-article-template-js": () => import("./../../../src/templates/articleTemplate.js" /* webpackChunkName: "component---src-templates-article-template-js" */),
  "component---src-templates-campaign-template-js": () => import("./../../../src/templates/campaignTemplate.js" /* webpackChunkName: "component---src-templates-campaign-template-js" */),
  "component---src-templates-content-template-js": () => import("./../../../src/templates/contentTemplate.js" /* webpackChunkName: "component---src-templates-content-template-js" */),
  "component---src-templates-governance-page-template-js": () => import("./../../../src/templates/governancePageTemplate.js" /* webpackChunkName: "component---src-templates-governance-page-template-js" */),
  "component---src-templates-member-template-js": () => import("./../../../src/templates/memberTemplate.js" /* webpackChunkName: "component---src-templates-member-template-js" */),
  "component---src-templates-news-template-js": () => import("./../../../src/templates/newsTemplate.js" /* webpackChunkName: "component---src-templates-news-template-js" */),
  "component---src-templates-resource-template-js": () => import("./../../../src/templates/resourceTemplate.js" /* webpackChunkName: "component---src-templates-resource-template-js" */),
  "component---src-templates-workshop-template-js": () => import("./../../../src/templates/workshopTemplate.js" /* webpackChunkName: "component---src-templates-workshop-template-js" */)
}

