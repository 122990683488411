import React from "react"
import { Provider } from "react-redux"
import { PersistGate } from "redux-persist/es/integration/react"
import { getPersistor } from "@rematch/persist"
import { ThemeProvider } from "theme-ui"
import { Themed } from '@theme-ui/mdx'

import theme from "@src/theme"
import store from "@store"

export default ({ element }) => (
    <Provider store={store}>
      <PersistGate persistor={getPersistor()}>
        <Themed.root>{element} </Themed.root>
      </PersistGate>
    </Provider>
)
