import React from "react"
import "normalize.css/normalize.css"
import { createGlobalStyle } from "styled-components"
import { Themed } from "@theme-ui/mdx"
import theme, { rem } from "@src/theme"

export const GlobalStyle = createGlobalStyle`



  * {
    box-sizing: border-box;

  }

  html {
    -webkit-print-color-adjust: exact;
    font-family: "Din";
    font-weight: 500;
    -webkit-font-smoothing: subpixel-antialiased;
    -moz-osx-font-smoothing: grayscale;
    line-height: 1.5;
    letter-spacing: 0.25px;
    font-size: 18px;
    position: relative;
    color: ${theme.colors.darkPurple};
  }


  body {
    min-height: 100%;
  }


  body.modal-opened {
   overflow: hidden;
}

  ul {
    margin: 0;
    padding: 0;
  }

  .link-heading {
    font-family: "Futura";
    font-weight: 900;
    border-bottom: 2px solid ${theme.colors.darkPurple};
    color: ${theme.colors.darkPurple};
    text-decoration: none;
    /* text-transform: uppercase; */
    cursor: pointer;
  }

  .link {
    color: ${theme.colors.darkPurple};
    text-decoration: underline;
    cursor: pointer;
  }


  .flex-align-center {
    display: flex;
    align-items: center;
  }
  .flex-justify-center {
    display: flex;
    justify-content: center;
  }

  .blue-linear {
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(221, 243, 255, 0) 0.01%, rgba(14, 125, 185, 0.5) 100%), #DDF3FF;
  }
  .pink-linear {
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(132, 106, 175, 0.5) 100%), #FFD1DC;
  }

  .object-fit {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
  }

  



 .p-left {
   padding-left: 2.5vw;
   padding-right: 2.5vw;
 }

  button {
    background: none;
    border: none;
    padding: 0;
    margin: 0;
    cursor: pointer;
  }

  .unstyled-link {
    text-decoration: none;
    color: currentColor;
  }

  /* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance:textfield;
}

label.field-label {
  display: block;
  font-size: ${rem(18)};
  font-family: ${theme.fonts.heading};
  /* text-transform: uppercase; */
}

fieldset {
  border: 0;
    margin: 0;
    padding: 0;
}

ul {
  margin: 0;
}
`

export default ({ element }) => (
  <React.Fragment>
    <Themed.root>{element}</Themed.root>
  </React.Fragment>
)
